import React from 'react';
import { Link } from 'react-router-dom';
import ScrollableContentLayout from '../layouts/ScrollableContentLayout';
import { useAuth } from '../contexts/AuthContext';

const LandingPage: React.FC = () => {
  const { authState } = useAuth();

  return (
    <ScrollableContentLayout>
      <div className="p-4">

        {/* Hero Section */}
        <section className="bg-base-100 min-h-[80vh] flex justify-center">
          <div className="grid max-w-screen-xl px-4 py-8 mx-auto lg:gap-8 xl:gap-0 lg:py-16 lg:grid-cols-12">
            <div className="mr-auto place-self-center lg:col-span-7">
              <h1 className="max-w-2xl mb-4 text-4xl font-extrabold leading-none md:text-5xl xl:text-6xl dark:text-white">An Education Platform for Everyone</h1>
              <p className="max-w-2xl mb-6 font-light text-gray-500 lg:mb-8 md:text-lg lg:text-xl dark:text-gray-400">Whether you&apos;re aiming for top grades or mastering complex concepts, our personalized approach ensures you achieve your academic goals with confidence.</p>
              <Link to={authState.isAuthenticated ? '/home' : '/authenticate?mode=signup'} className="btn btn-primary mx-2">
                Get Lessons
                <svg className="w-5 h-5 ml-2 -mr-1" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                  <path
                    fillRule="evenodd"
                    d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </Link>
              <Link to={authState.isAuthenticated ? '/home' : '/authenticate?mode=signup'} className="btn btn-secondary mx-2">
                Become a Tütor
              </Link>
            </div>
            <div className="hidden lg:mt-0 lg:col-span-5 lg:flex">
              <img
                src="https://notioly.com/wp-content/uploads/2022/07/152.Study-Group.png"
                alt="mockup"
                className="object-contain invert grayscale-0 mix-blend-difference"
              />
            </div>
          </div>
        </section>
        {/* Sub Text Information */}
        <section className="bg-base-100 py-12">
          <div className="max-w-screen-xl  mx-auto px-4">
            <div className="max-w-4xl">
              <h2 className="text-4xl font-bold mb-4">
                Join over
                {' '}
                <span className="font-extrabold">200+</span>
                {' '}
                students and tutors worldwide
              </h2>
              <p className="mb-4">Manage and monitor student progress across all courses with an integrated, collaborative platform. Connect assignments, feedback, and academic records across systems to give tutors and support staff comprehensive insights for a more personalized learning experience.</p>
              <p className="mb-4 font-medium">Provide and access exceptional tutoring experiences quickly, sidestepping the complexities of traditional educational platforms.</p>
              <Link to="/authenticate" className="link link-primary">
                Learn more
              </Link>
            </div>
          </div>
        </section>

        {/* Additional Information Section */}
        <section className="bg-base-200 py-12">
          <div className="max-w-screen-xl  mx-auto px-4">
            <div className="grid lg:grid-cols-2 gap-8 items-center">
              <div>
                <h2 className="text-4xl font-bold mb-4">Enhancing the Classroom Experience</h2>
                <p className="mb-4">
                  We&apos;re very (very) picky about who we let tutor on our platform - just 1 in 8
                  who apply make the cut. They&apos;re experts in over 30 subjects from
                  KS2 up to GCSE and A Level.
                </p>
                <p>
                  Because they&apos;re from UK unis, they studied (and aced) the same courses
                  as your teen in the last few years. So they explain tricky concepts in a way
                  teens understand - and they double as cool older role models.
                </p>
              </div>
              <div className="grid grid-cols-2 gap-4">
                <img className="w-full rounded-lg shadow-lg" src="https://notioly.com/wp-content/uploads/2022/01/79.Login_.png" alt="office content 1" />
                <img className="w-full rounded-lg shadow-lg mt-4 lg:mt-10" src="https://notioly.com/wp-content/uploads/2022/05/136.Achievement.png" alt="office content 2" />
              </div>
            </div>
          </div>
        </section>

        {/* Product Information */}
        <section className="bg-base-100 py-12">
          <div className="max-w-screen-xl  mx-auto px-4">
            <div className="max-w-2xl mb-12">
              <h2 className="text-4xl font-bold mb-4">Perfect for Teachers and Students</h2>
              <p>
                Here at Tutr.ai we focus on providing a high quality
                education and having the correct technology to empower effective learning.
              </p>
            </div>
            <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
              <div className="card bg-base-200 shadow-xl">
                <div className="card-body">
                  <div className="flex justify-center items-center mb-4 w-10 h-10 rounded-full bg-blue-100 lg:h-12 lg:w-12 dark:bg-blue-900">
                    <svg className="w-5 h-5 text-blue-600 lg:w-6 lg:h-6 dark:text-primary" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M3 3a1 1 0 000 2v8a2 2 0 002 2h2.586l-1.293 1.293a1 1 0 101.414 1.414L10 15.414l2.293 2.293a1 1 0 001.414-1.414L12.414 15H15a2 2 0 002-2V5a1 1 0 100-2H3zm11.707 4.707a1 1 0 00-1.414-1.414L10 9.586 8.707 8.293a1 1 0 00-1.414 0l-2 2a1 1 0 101.414 1.414L8 10.414l1.293 1.293a1 1 0 001.414 0l4-4z" clipRule="evenodd" /></svg>
                  </div>
                  <h3 className="card-title">Goal-Oriented Learning</h3>
                  <p>
                    Chart a direct path to academic achievement with tutors
                    who align with your educational ambitions. Each session
                    is a step towards your GCSE and A-level goals.
                  </p>
                </div>
              </div>

            </div>
          </div>
        </section>

        {/* Call to Action */}
        <section className="bg-base-200 py-12">
          <div className="container mx-auto px-4 text-center">
            <h2 className="text-4xl font-bold mb-4">Get your first lesson today!</h2>
            <p className="mb-6">Sign up and see the available courses. No credit card required.</p>
            <Link to="/authenticate" className="btn btn-primary">Get Started</Link>
          </div>
        </section>

      </div>
    </ScrollableContentLayout>
  );
};

export default LandingPage;
